import Link from "next/link";

export default function BottomBar() {
    return (
        <div className="z-50 bottom-0 fixed inset-x-0 h-16 bg-house-pink flex flex-row items-center justify-evenly">

            <Link replace href={"/"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    className="w-10 h-10 text-gray-700"
                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 3v18h18" />
                    <path d="M20 18v3" />
                    <path d="M16 16v5" />
                    <path d="M12 13v8" />
                    <path d="M8 16v5" />
                    <path d="M3 11c6 0 5 -5 9 -5s3 5 9 5" />
                </svg>
            </Link>

            <Link replace href={"/checks"}>
                <svg className="w-10 h-10 text-gray-700"
                    viewBox="0 0 24 24"
                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8" />
                    <path d="M14 19l2 2l4 -4" />
                    <path d="M9 8h4" />
                    <path d="M9 12h2" />
                </svg>
            </Link>


            <Link replace href={"/items"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    className="w-10 h-10 text-gray-700"
                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M13 5h8" />
                    <path d="M13 9h5" />
                    <path d="M13 15h8" />
                    <path d="M13 19h5" />
                    <rect x="3" y="4" width="6" height="6" rx="1" />
                    <rect x="3" y="14" width="6" height="6" rx="1" />
                </svg>
            </Link>


            <Link replace href={"/addItem"}>
                <svg viewBox="0 0 24 24"
                    fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                    className="w-10 h-10 text-gray-700"
                >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                </svg>
            </Link>

        </div>
    );
}