import Head from 'next/head';
import Image from 'next/image';
import BottomBar from '../components/bottombar';
import { useEffect, useState } from 'react';
import Month from '../components/month';
import WaitingInline from '../components/waitingInline';

import 'chartist/dist/index.css';
import { PieChart } from 'chartist';

export default function Home() {
  const [fetching, setFetching] = useState(true);
  const [summaryCategory, setSummaryCategory] = useState([10, 10, 0]);

  useEffect(() => {

    const dataFetch = async () => {
      const sumCategory = await (
        await fetch(
          "/api/getSumCategory"
        )
      ).json();
      const sumC = [0, 0, 0];
      //@ts-ignore
      sumCategory.rows.forEach(it => sumC[it.category] = it.summary);
      sumC[0] = sumC[1] + sumC[2];

      setSummaryCategory(sumC);
      setFetching(false);
    };
    setFetching(true);
    dataFetch();

  }, []);

  useEffect(() => {

    const chart = new PieChart(
      '#chart',
      {
        series: [summaryCategory[1], summaryCategory[2]]
      },
      {
        donut: true,
        donutWidth: 30,
        startAngle: 0,
        showLabel: false
      }
    )
    const colors = ['#4ade80', '#f87171'];
    chart.on('draw', function (context) {
      if (context.type === 'slice') {
        context.element.attr({
          style: `
            stroke: ${colors[context.index]};
            stroke-width: 30px;
          `
        });
      }
    });

  }, [summaryCategory]);


  return (
    <>
      <Head>
        <title>เดอะ บ้าน</title>
        <meta name="description" content="The House Project App" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#efb5e7" />

        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className="flex flex-col">

        <div className="flex flex-row items-center space-x-2 bg-house-pink">
          <div className="relative h-16 w-16">
            <Image
              src={"/android-chrome-384x384.png"} alt="logo"
              fill
              className="object-cover"
            />
          </div>
          <div className="text-2xl font-bold">เดอะ บ้าน</div>
        </div>


        <div className="flex flex-col items-center mt-8 mb-16 space-y-4">

          <div className="border-2 rounded-xl w-11/12 md:w-1/2 pb-2">
            {
              fetching
                ?
                <div className="flex flex-col justify-center items-center w-full h-[84px]">
                  <div className="w-10 h-10">
                    <WaitingInline />
                  </div>
                </div>
                :
                <div className="flex flex-col items-center">
                  <svg className="w-12 h-12 text-house-pink"
                    viewBox="0 0 24 24"
                    strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 6h5a3 3 0 0 1 3 3v.143a2.857 2.857 0 0 1 -2.857 2.857h-5.143" />
                    <path d="M8 12h5a3 3 0 0 1 3 3v.143a2.857 2.857 0 0 1 -2.857 2.857h-5.143" />
                    <path d="M8 6v12" />
                    <path d="M11 4v2" />
                    <path d="M11 18v2" />
                  </svg>
                  <div className="font-bold text-3xl text-gray-700">{summaryCategory[0].toLocaleString()}</div>
                </div>
            }


          </div>


          <div className="border-2 rounded-xl flex flex-row items-center justify-center w-11/12 md:w-1/2">
            <div className="relative w-40 h-40 mt-2">
              <div id="chart"></div>
            </div>



            <div className="flex flex-col items-center p-2 space-y-2">
              <div className="flex flex-row items-center justify-start w-full">
                <svg
                  className="w-8 h-8 p-1 rounded-full text-gray-700 bg-green-400"
                  viewBox="0 0 24 24"
                  strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.072 20.998h-14.144a1.928 1.928 0 0 1 -1.928 -1.928v-6.857c0 -.512 .203 -1.003 .566 -1.365l7.07 -7.063a1.928 1.928 0 0 1 2.727 0l7.071 7.063c.363 .362 .566 .853 .566 1.365v6.857a1.928 1.928 0 0 1 -1.928 1.928z" />
                  <path d="M7 13v4h10v-4l-5 -5" />
                  <path d="M14.8 5.2l-11.8 11.8" />
                  <path d="M7 17v4" />
                  <path d="M17 17v4" />
                </svg>
                <svg className="w-8 h-8 text-house-pink"
                  viewBox="0 0 24 24"
                  strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 6h5a3 3 0 0 1 3 3v.143a2.857 2.857 0 0 1 -2.857 2.857h-5.143" />
                  <path d="M8 12h5a3 3 0 0 1 3 3v.143a2.857 2.857 0 0 1 -2.857 2.857h-5.143" />
                  <path d="M8 6v12" />
                  <path d="M11 4v2" />
                  <path d="M11 18v2" />
                </svg>
                {
                  fetching
                    ?
                    <div className="w-8 h-8">
                      <WaitingInline />
                    </div>
                    :
                    <div className="font-bold text-2xl text-gray-700">{summaryCategory[1].toLocaleString()}</div>
                }

              </div>

              <div className="flex flex-row items-center justify-start w-full">
                <svg
                  className="w-8 h-8 p-1 rounded-full text-gray-700 bg-red-400"
                  viewBox="0 0 24 24"
                  strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M13 9l7.383 7.418c.823 .82 .823 2.148 0 2.967a2.11 2.11 0 0 1 -2.976 0l-7.407 -7.385" />
                  <path d="M6.66 15.66l-3.32 -3.32a1.25 1.25 0 0 1 .42 -2.044l3.24 -1.296l6 -6l3 3l-6 6l-1.296 3.24a1.25 1.25 0 0 1 -2.044 .42z" />
                </svg>
                <svg className="w-8 h-8 text-house-pink"
                  viewBox="0 0 24 24"
                  strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 6h5a3 3 0 0 1 3 3v.143a2.857 2.857 0 0 1 -2.857 2.857h-5.143" />
                  <path d="M8 12h5a3 3 0 0 1 3 3v.143a2.857 2.857 0 0 1 -2.857 2.857h-5.143" />
                  <path d="M8 6v12" />
                  <path d="M11 4v2" />
                  <path d="M11 18v2" />
                </svg>
                {
                  fetching
                    ?
                    <div className="w-8 h-8">
                      <WaitingInline />
                    </div>
                    :
                    <div className="font-bold text-2xl text-gray-700">{summaryCategory[2].toLocaleString()}</div>
                }
              </div>


            </div>




          </div>


          <div className="pt-6 grid grid-cols-2 gap-8">
            {/* <div className="flex flex-col items-end">
              <div className="text-gray-600">เมษายน 2566</div>
              <Month month={4} year={2023} dates={[1]} />
            </div> */}

            <div className="flex flex-col items-end">
              <div className="text-gray-600">มีนาคม 2566</div>
              <Month month={3} year={2023} dates={[7, 16, 25]} />
            </div>

            <div className="flex flex-col items-end">
              <div className="text-gray-600">กุมภาพันธ์ 2566</div>
              <Month month={2} year={2023} dates={[4, 11]} />
            </div>

            <div className="flex flex-col items-end">
              <div className="text-gray-600">มกราคม 2566</div>
              <Month month={1} year={2023} dates={[7, 15, 28]} />
            </div>

            <div className="flex flex-col items-end">
              <div className="text-gray-600">ธันวาคม 2565</div>
              <Month month={12} year={2022} dates={[24, 26, 27, 29, 30]} />
            </div>

          </div>


        </div>

        <BottomBar />
      </main>
    </>
  )
}
