export default function WaitingInline() {
    return (
        <svg className="animate-spin text-gray-700"
            viewBox="0 0 24 24"
            strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 17a3 3 0 1 1 -1.543 -2.623l2.087 -3.754a3 3 0 0 1 1.456 -5.623a3 3 0 0 1 1.457 5.623l2.087 3.754a3 3 0 1 1 -1.538 2.8l-.006 -.177h-4z" />
            <path d="M17 17v.01" />
            <path d="M7 17v.01" />
            <path d="M12 8v.01" />
        </svg>
    );
}