import { useEffect, useState } from "react";

type Props = {
    month: number;
    year: number;
    width?: number;
    height?: number;
    spacing?: number;
    bgColor?: string;
    grayColor?: string;
    emptyColor?: string;
    fillColor?: string;
    dates?: number[];
}

// grayColor = "#e2e8f0"
export default function Month({
    month, year,
    width = 12, height = 12, spacing = 5,
    bgColor = "#fff", grayColor = "#fff",
    emptyColor = "#efb5e7", fillColor = "#db2777",
    dates = []
}: Props) {
    month--;
    const maxWidth = 7 * (width + spacing) - spacing;
    const maxHeight = 7 * (height + spacing) - spacing;

    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const dayStart = new Date(year, month, 1).getDay();
    const dayEnd = new Date(year, month, daysInMonth).getDay();
    const weeksBetween = (daysInMonth - (7 - dayStart) - (dayEnd + 1)) / 7;

    const weekOfMonth = (date: number) => {
        const daysInFirstWeek = 7 - dayStart;
        const nDate = date - daysInFirstWeek;
        return Math.ceil(nDate / 7);
    }

    const mark = () => {
        let res: number[][] = [[], [], [], [], []];
        if (dates.length > 0) {
            dates.forEach(it => {
                const d = new Date(year, month, it).getDay();
                res[weekOfMonth(it)].push(d);
            })
        }
        return res;
    }

    const markRow = mark();

    const drawRow = (row: number, n: number, start: number, end: number) => {
        return (
            Array.from(Array(n).keys()).map((i) => {
                let fillWith = "#fff";
                if ((i < start) || (i > end)) {
                    fillWith = grayColor;
                } else {
                    if (markRow[row].includes(i)) {
                        fillWith = fillColor;
                    } else {
                        fillWith = emptyColor;
                    }
                }
                return <rect key={`${row}_${i}`} x={i * (width + spacing)} y={row * (width + spacing)} width={width} height={height} fill={fillWith} />;
            })
        );
    }

    // useEffect(() => {
    //     // markRow = mark();
    //     console.log('call mark(x)');
    //     if (dates.length > 0) {
    //         dates.forEach(it => {
    //             const d = new Date(year, month, it).getDay();
    //             markRow[weekOfMonth(it)].push(d);
    //         })
    //     }
    //     console.log(markRow);
    // }, []);

    return (
        <div className="">
            <svg width={maxWidth} height={maxHeight} viewBox={`0 0 ${maxWidth} ${maxHeight}`}>
                <rect x="0" y="0" width={maxWidth} height={maxHeight} fill={bgColor} />
                <g>
                    {drawRow(0, 7, dayStart, 6)}
                    {
                        Array.from(Array(weeksBetween).keys()).map((i) => {
                            return drawRow(i + 1, 7, 0, 6);
                        })
                    }
                    {drawRow(weeksBetween + 1, 7, 0, dayEnd)}
                </g>
            </svg>

        </div>
    );
}